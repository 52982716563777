<!-- 安详禅结缘品 -->


<!-- 下载匣 -->
  <template>
  <div class="word">
    <div class="title ta-l">
      ({{ $t("numList1")[0] }}) {{ contentList1.cate_name }}
    </div>
    <table cellspacing="1" style="border-spacing: 1">
      <tr>
        <th class="list1">{{ $t("downloadCate") }}</th>
        <th class="list2">{{ $t("downName") }}</th>
        <!-- 装订版本 -->
        <th class="list3">{{ $t("bookBinding") }}</th>
        <th class="list4">{{ $t("Images") }}</th>
        <th class="list5">{{ $t("downloadConten") }}</th>
      </tr>

      <tr v-for="(item1, index1) in contentList1.lists" :key="index1">
        <td>{{ item1.goods_cate }}</td>
        <td style="white-space: pre-wrap">{{ item1.goods_title }}</td>
        <!-- 装订版本 -->
        <td>{{ item1.status }}</td>
        <td>
          <img style="object-fit: contain" :src="item1.goods_url" alt />
        </td>
        <td @click="topages(item1)" style="cursor: pointer">
          {{ item1.goods_content }}
        </td>
      </tr>
    </table>
    <div class="title ta-l">
      ({{ $t("numList1")[1] }}) {{ contentList2.cate_name }}
    </div>
    <table cellspacing="1" style="border-spacing: 1">
      <tr>
        <th class="list1_1">{{ $t("downloadCate") }}</th>
        <th class="list2_1">{{ $t("downName") }}</th>

        <th class="list3_1">{{ $t("Images") }}</th>
        <th class="list4_1">
          <!-- {{ $t("downloadExplain") }} -->
          内容
        </th>
        <!-- 下载页面 -->
        <!-- <th class="list5_1"> -->
        <!-- {{ $t("downloadPage") }} -->
        <!-- 内容 -->
        <!-- </th> -->
      </tr>

      <tr v-for="(item1, index1) in contentList2.lists" :key="index1">
        <td>{{ item1.goods_cate }}</td>
        <td style="white-space: pre-wrap">{{ item1.goods_title }}</td>

        <td class="list3_1">
          <img
            style="object-fit: contain;!important"
            :src="item1.goods_url"
            alt
          />
        </td>
        <!-- @click="topages(item1)" -->
        <td>
          <a :href="item1.link" style="text-decoration: none; color: #2c2c2c">{{
            item1.goods_content
          }}</a>
        </td>
        <!-- 下载页面 -->
        <!-- <td>
          <a
            download
            :href="item1.link"
            style="text-decoration: none; color: #3890d7"
            >{{ $t("downLoad") }}</a
          >
        </td> -->
      </tr>
    </table>
    <div class="title ta-l">
      ({{ $t("numList1")[2] }}) {{ contentList3.cate_name }}
    </div>
    <table cellspacing="1" style="border-spacing: 1">
      <tr>
        <th class="list1">{{ $t("downloadCate") }}</th>
        <!-- <th class="list2" style="width: 302px">{{ $t("downName") }}</th> -->
        <th class="list2" style="width: 200px">{{ $t("downName") }}</th>

        <th class="list4" style="width: 137px">{{ $t("Images") }}</th>
        <!-- <th class="list5" style="width:203px">{{$t('downloadPage')}}</th> -->
        <!-- <th class="list5" style="width:203px">{{$t('downloadPage')}}</th> -->
        <th class="list5" style="width: 155px">{{ $t("downloadConten") }}</th>
      </tr>

      <tr v-for="(item1, index1) in contentList3.lists" :key="index1">
        <td>{{ item1.goods_cate }}</td>
        <td style="white-space: pre-wrap">{{ item1.goods_title }}</td>

        <td>
          <img
            style="object-fit: contain;!important"
            :src="item1.goods_url"
            alt
          />
        </td>
        <td @click="topages(item1)" style="cursor: pointer">
          {{ item1.goods_content }}
        </td>
      </tr>
    </table>
    <div class="title ta-l">
      ({{ $t("numList1")[3] }}) {{ contentList4.cate_name }}
    </div>
    <table cellspacing="1" style="border-spacing: 1">
      <tr>
        <th class="list1">{{ $t("downloadCate") }}</th>
        <th class="list2" style="width: 230px">{{ $t("downName") }}</th>
        <!-- 装订版本 -->
        <th class="list3" style="width: 60px">{{ $t("bookBinding") }}</th>
        <th class="list4">{{ $t("Images") }}</th>
        <!-- <th class="list5" style="width: 155px">{{ $t("downloadConten") }}</th> -->
      </tr>

      <tr v-for="(item1, index1) in contentList4.lists" :key="index1">
        <td>{{ item1.goods_cate }}</td>
        <td style="white-space: pre-wrap">{{ item1.goods_title }}</td>
        <!-- 装订版本 -->
        <td style="white-space: pre-wrap">{{ item1.status }}</td>
        <td>
          <img
            style="object-fit: contain;!important"
            :src="item1.goods_url"
            alt
          />
        </td>
        <td @click="topages(item1)" v-if="item1.goods_content">
          {{ item1.goods_content }}
        </td>
      </tr>
    </table>
    <div class="title getWayTitle ta-l mt20">{{ acquisition.title2 }}</div>
    <div class="getWay" v-html="acquisition.content2"></div>
  </div>
</template>

<script>
import { getCateList, getGoodsList, getAcquisitionList } from "../../api/apis";
export default {
  name: "",
  components: {},

  data() {
    return {
      contentList1: {}, //展示列表
      contentList2: {}, //展示列表
      contentList3: {}, //展示列表
      contentList4: {}, //展示列表
      acquisition: {},
    };
  },

  methods: {
    topages(item1) {
      //点击表格最后一项跳转相应页面
      if (item1.link !== null) {
        window.open(item1.link, "_self");
      } else {
        console.log(item1.link, "没有链接");
      }
    },
    //文章详情
    linkToDetail(item) {
      window.open(
        this.$BaseURL +
          "/articleDetail?name=word&id=" +
          item.id +
          "&title=" +
          this.title
      );
      // this.$router.push({
      //     path: "/articleDetail",
      //     query: { name: "word", id: item.id, title: this.title }
      // });
    },
  },
  created() {
    getCateList().then((res) => {
      let id = res[4][0].id;
      getGoodsList(id).then((res) => {
        this.contentList = res;
        //this.contentList = res;
        this.contentList1 = res[0] || { lists: {} };
        console.log(this.contentList1);
        this.contentList2 = res[1] || { lists: {} };
        this.contentList3 = res[2] || { lists: {} };
        this.contentList4 = res[3] || { lists: {} };
        console.log(this.contentList4);
      });
      getAcquisitionList().then((res) => {
        this.acquisition = res;
      });
    });
  },
};
</script>

<style lang="stylus" scoped>
.ta-l {
  text-align: left;
}

.pl5 {
  padding-left: 5px;
}

.word {
  width: 750px;
  margin: 20px 10px;
  color: #2c2c2c;
  margin-top: -5px;

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 50px;
    // margin-top: -10px;
  }

  .getWayTitle {
    margin-top: 10px;
  }
}

table, td, tr, th {
  border: 1px solid #ACACAC;
  border-collapse: separate;
  border-spacing: 1;
  vertical-align: middle;
  text-align: left;
}

table {
  border: 2px solid #2C2C2C;
  border-collapse: separate;
  border-spacing: 1px;
}

.tableBottomLine {
  width: 100%;
  height: 1px;
  background-color: #ACACAC;
}

table {
  width: 100%;
  font-size: 14px;
  table-layout: fixed;
  border-collapse: collapse !important;

  th {
    line-height: 36px;
    font-weight: 400;
    font-size: 14px;
    // padding-left: 14px;
  }

  td {
    line-height: 30px;
    height: 78px;
    padding: 3px;
  }

  td {
    padding: 3px;
    overflow: hidden;
    box-sizing: border-box;

    img {
      object-fit: contain;
      // width: 100%;
    }
  }

  .list1 {
    width: 65px;
  }

  .list2 {
    width: 168px;
  }

  .list3 {
    width: 51px;
  }

  .list4 {
    width: 300px;
  }

  .list5 {
    width: 130px;
    padding-left: 10px;
  }

  .list1_1 {
    // width: 119px;
  }

  .list2_1 {
    // width: 238px;
    width: 145px;
  }

  .list3_1 {
    // width: 158px;
    width: 250px;
  }

  .list4_1 {
    width: 119px;
  }

  .list5_1 {
    width: 120px;
    padding-left: 10px;
  }
}

.titleStyle {
  line-height: 60px;
  margin-top: 30px;
  text-align: left;
}

.getWay {
  line-height: 26px;
  text-align: left;
}
</style>